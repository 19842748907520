import React, { useState, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import Tilt from "react-tilt";
import tw, { styled } from "twin.macro";
import AOS from "aos";
import { Helmet } from "react-helmet";
import "aos/dist/aos.css";
import "@fontsource/rubik-mono-one";
import "@fontsource/lato";
import Logo from "../images/svg/logo.svg";
import YoutubeIcon from "../images/svg/youtube.svg";
import ZkbIcon from "../images/svg/zkb.svg";
import DiscordIcon from "../images/svg/discord.svg";
import Button from "../components/Button";
import Background from "../components/Background";
import favicon from "../images/favicon.ico";
import Portrait from "../components/Portrait";
if (typeof window !== "undefined") {
  AOS.init();
}

const GlobalStyle = createGlobalStyle`
  body {
  background: #141e30; /* fallback for old browsers */
    color: white;
    margin: 0;
    font-family: "Lato", sans-serif;
  }
`;

const Content = styled.div`
  ${tw`flex-1
  flex
  flex-col
  justify-center
  items-center
  h-full
pb-20
`}
  background-color: #141e30;
`;

const Topbar = styled.div`
  ${tw`
relative
  flex
  flex-col
  justify-center
  items-center
  pt-20 pb-20`}
  background-repeat: no-repeat;
  background-attachment: fixed;
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #243b55,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

const TopbarContent = styled.div`
  ${tw`flex-1
  flex
  flex-col
  justify-center
  items-center
  w-full`}
  z-index: 1;
`;

const Title = styled.h1`
  ${tw`text-4xl md:text-7xl`}
  font-family: "Rubik Mono One", sans-serif;
`;

const Header = styled.h1`
  ${tw`text-2xl md:text-4xl`}
  font-family: "Rubik Mono One", sans-serif;
  &:before,
  &:after {
    background-color: #333;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 100%;
  }
`;

const Text = tw.h2`
  -mt-2
  mb-4
  text-center
  md:text-xl
  text-base
`;

const ButtonContainer = tw.div`
  text-center
  mt-10
  flex
  flex-col
`;

const StatContainer = tw.div`
  flex
  justify-center
  m-4
  space-x-2
`;

const Stat = styled.div`
  background-color: #cf4339;
  ${tw`px-4 py-2 first:rounded-l-lg last:rounded-r-lg ring-4 shadow-lg text-center`}
`;

const StyledYoutubeIcon = styled(YoutubeIcon)`
  ${tw`inline mr-2 text-white fill-current group-hover:text-red-500`}
  height: 18px;
  width: auto;
`;

const StyledDiscordIcon = styled(DiscordIcon)`
  ${tw`inline mr-2 text-white fill-current group-hover:text-red-500`}
  height: 18px;
  width: auto;
`;

const StyledZkbIcon = styled(ZkbIcon)`
  ${tw`inline mr-2 text-white fill-current group-hover:text-red-500`}
  height: 10px;
  width: auto;
  stroke: currentColor:
`;

const Anchor = styled.a`
  ${tw`mt-2 hover:text-red-500 rounded-lg`}
`;

const Star = styled.span`
  color: #ffed9f;
`;

const StyledLogo = styled(Logo)`
  height: 300px;

  transform: perspective(800px) rotateY(25deg) scale(0.9) rotateX(10deg);
  filter: blur(2px);
  opacity: 0.5;
  transition: 0.6s ease all;

  &:hover {
    transform: perspective(800px) rotateY(-15deg) translateY(-50px)
      rotateX(10deg) scale(1);
    filter: blur(0);
    opacity: 1;
  }
`;

const PortraitContainer = styled.div`
  ${tw`flex flex-col flex-wrap md:flex-row items-center justify-center`}
`;

const CEOPortrait = styled(Portrait)`
  ${tw`md:w-56`}
`;

const toBillion = (number) => (number / 1000000000000).toFixed(2) + "t";

const toThousand = (number) => (number / 1000).toFixed(0) + "k";

const IndexPage = () => {
  const [stats, setStats] = useState(null);
  useEffect(() => {
    async function fetchStats() {
      const res = await fetch(
        "https://zkillboard.com/api/stats/corporationID/98155413/"
      );
      setStats(await res.json());
    }
    fetchStats();
  }, []);

  return (
    <>
      <Helmet>
        <title>Space★Mutts</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <GlobalStyle />
      <Topbar>
        <Background />
        <TopbarContent>
          <Title>
            SPACE<Star>★</Star>MUTTS
          </Title>
          <Text>
            Tight-knit group focused on brawls, nanogang and giggles
          </Text>
          <StatContainer>
            <Stat>
              <b>ISK destroyed:</b> {toBillion(stats?.iskDestroyed || 0)}
            </Stat>
            <Stat>
              <b>Ships destroyed:</b> {toThousand(stats?.shipsDestroyed || 0)}
            </Stat>
            <Stat>
              <b>Member count:</b> {stats?.info.memberCount || 0}
            </Stat>
          </StatContainer>
          <Tilt>
            <div data-aos="zoom-in" data-aos-duration="1500">
              <StyledLogo />
            </div>
          </Tilt>
          <ButtonContainer>
            <Button href={"https://auth.spacemutts.com"}>Join Us</Button>
            <Anchor className="group" href="https://discord.gg/aF5ccK8Fxv">
              <StyledDiscordIcon />
              Discord
            </Anchor>
            <Anchor
              className="group"
              href="https://zkillboard.com/corporation/98155413/"
            >
              <StyledZkbIcon />
              zKillboard
            </Anchor>
            <Anchor
              className="group"
              href="https://www.youtube.com/channel/UCNFqChJ4O-XH0rvLm1g-YEg"
            >
              <StyledYoutubeIcon />
              YouTube
            </Anchor>
          </ButtonContainer>
        </TopbarContent>
      </Topbar>
      <Content>
        <Header>Leadership</Header>
        <PortraitContainer>
          <CEOPortrait
            characterId={679616599}
            name="xeroa1984"
            role="CEO"
          />
          <CEOPortrait
            characterId={779019080}
            name="Apopolis"
            role="Director"
          />
        </PortraitContainer>
      </Content>
    </>
  );
};

export default IndexPage;
